import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import { useAuth } from '../../components/Auth/useAuth'

const AdminRedirectHoc = ({ children }) => {
  const auth = useAuth()
  const [isAdmin, setIsAdmin] = useState(null)

  useEffect(() => {
    if (auth.user) {
      const idx =
        auth.user.signInUserSession?.accessToken?.payload[
          'cognito:groups'
        ]?.indexOf('hcpd-admin') ?? null
      if (idx === null) {
        navigate('/admin')
      } else if (idx >= 0) {
        setIsAdmin(true)
      } else if (idx < 0) {
        navigate('/admin')
      }
    } else if (auth.user === false) {
      console.log('auth.user === false')
      navigate('/admin')
    }
  }, [auth])

  if (!auth.user) {
    return null
  }

  if (isAdmin) {
    return <>{children}</>
  }

  return null
}

export default AdminRedirectHoc
