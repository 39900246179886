import React from 'react'

import * as Styled from './styles/ContentExpiryList'
import { RegionContainer } from '../styles/Utility.styles'

const PRISMIC_URL_PREFIX =
  'https://npf-website.prismic.io/documents~b=working&c=published&l=en-us/'
const PRISMIC_URL_SUFFIX = '/?section=Content%20Expiration'

const NoExpiringItems = () => (
  <RegionContainer>
    <Styled.NoItemsMessage>No expiring items</Styled.NoItemsMessage>
  </RegionContainer>
)

const ContentExpiryList = ({ items }) => {
  if (items.length <= 0) {
    return <NoExpiringItems />
  }
  return (
    <RegionContainer>
      <Styled.ExpiringItemsTable>
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <Styled.ExpiringItemsRow>
          <Styled.ExpiringItemsHeader>Expiring Page</Styled.ExpiringItemsHeader>
          <Styled.ExpiringItemsHeader>Page Type</Styled.ExpiringItemsHeader>
          <Styled.ExpiringItemsHeader>
            Expiration Date
          </Styled.ExpiringItemsHeader>
        </Styled.ExpiringItemsRow>
        {items.map(item => (
          <Styled.ExpiringItemsRow key={item.id}>
            <Styled.ExpiringItemsData>
              <a
                href={`${PRISMIC_URL_PREFIX}${item.prismicId}${PRISMIC_URL_SUFFIX}`}
                alt="Prismic Link"
                rel="noreferrer"
                target="_blank"
              >
                {item.uid.replace('-', ' ')}
              </a>
            </Styled.ExpiringItemsData>
            <Styled.ExpiringItemsData>{item.pageType}</Styled.ExpiringItemsData>
            <Styled.ExpiringItemsData>
              {item.data.content_expiration_date}
            </Styled.ExpiringItemsData>
          </Styled.ExpiringItemsRow>
        ))}
      </Styled.ExpiringItemsTable>
    </RegionContainer>
  )
}

export default ContentExpiryList
