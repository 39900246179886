import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { ProvideAuth } from '../../components/Auth/useAuth'
import AdminRedirectHoc from '../../components/Auth/AdminRedirectHoc'
import ContentExpiryList from '../../components/Admin/ContentExpiryList'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import { node } from 'prop-types'

const ContentExpiryPage = ({ data }) => {
  const expiringAdvancedArticles = data.allPrismicAdvanceArticle?.edges?.map(
    edge => {
      edge.node.pageType = 'Advance Article'
      return edge.node
    }
  )
  const expiringSubpages = data.allPrismicSubpage?.edges?.map(edge => {
    edge.node.pageType = 'Subpage'
    return edge.node
  })
  const expringLandingPages = data.allPrismicLandingPage?.edges?.map(edge => {
    edge.node.pageType = 'Landing Page'
    return edge.node
  })
  const expiringMediaLibraryItems = data.allPrismicMediaLibraryItem?.edges?.map(
    edge => {
      edge.node.pageType = 'Media Library Item'
      return edge.node
    }
  )
  const allExpiringItems = [
    ...expiringAdvancedArticles,
    ...expiringSubpages,
    ...expringLandingPages,
    ...expiringMediaLibraryItems,
  ]
  return (
    <ProvideAuth>
      <AdminRedirectHoc>
        <Layout>
          <HcpdPageHero title="Expiring Content" body={{ richText: '' }} />
          <ContentExpiryList items={allExpiringItems} />
        </Layout>
      </AdminRedirectHoc>
    </ProvideAuth>
  )
}

export const query = graphql`
  {
    allPrismicAdvanceArticle(
      filter: { data: { content_expiration_date: { gt: "2000-01-01" } } }
    ) {
      edges {
        node {
          id
          uid
          prismicId
          data {
            content_expiration_date
          }
        }
      }
    }
    allPrismicSubpage(
      filter: { data: { content_expiration_date: { gt: "2000-01-01" } } }
    ) {
      edges {
        node {
          id
          uid
          prismicId
          data {
            content_expiration_date
          }
        }
      }
    }
    allPrismicLandingPage(
      filter: { data: { content_expiration_date: { gt: "2000-01-01" } } }
    ) {
      edges {
        node {
          id
          uid
          prismicId
          data {
            content_expiration_date
          }
        }
      }
    }
    allPrismicMediaLibraryItem(
      filter: { data: { content_expiration_date: { gt: "2000-01-01" } } }
    ) {
      edges {
        node {
          id
          uid
          prismicId
          data {
            content_expiration_date
          }
        }
      }
    }
  }
`

export default ContentExpiryPage
