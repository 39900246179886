import styled from 'styled-components'

export const NoItemsMessage = styled.h2`
  grid-column: 2 / 3;
`

export const ExpiringItemsTable = styled.table`
  grid-column: 2 / 3;
`

export const ExpiringItemsRow = styled.tr`
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`

export const ExpiringItemsData = styled.td`
  font-size: 2rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
`

export const ExpiringItemsHeader = styled.th`
  font-weight: 700;
  font-size: 2rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
`
